<script setup>
/******************************************************************************
 * PageComponent: DemoInfo
 *****************************************************************************/
import PageSection from "Components/PageSection/PageSection.vue";
import { useAuthStore } from "Stores/auth";
const auth = useAuthStore();
</script>

<template>
    <page-section hdl="Demoportal">
        <p>
            Dies ist die Demoversion vom IVU.Ableseportal. Alle Stammdaten sind
            anonymisiert.
        </p>
        <p>Testdaten (klicken zum übernehmen):</p>
        <ul>
            <li>
                <button
                    @click="
                        auth.customerNo = '170044';
                        auth.meterNo = '11024744';
                    "
                >
                    KNR: <cite>170044</cite>, ZAE: <cite>11024744</cite>
                </button>
            </li>
            <li>
                <button
                    @click="
                        auth.customerNo = '158844';
                        auth.meterNo = '3032242';
                    "
                >
                    KNR: <cite>158844</cite>, ZAE: <cite>3032242</cite>
                </button>
            </li>
            <li>
                <button
                    @click="
                        auth.customerNo = '102236';
                        auth.meterNo = '3031244';
                    "
                >
                    KNR: <cite>102236</cite>, ZAE: <cite>3031244</cite>
                </button>
            </li>
            <li>
                <a
                    href="/?h=8ea301ca7c2f9790c67738f1449b91175d0dc6880d31c9b5da7cb921336c7511"
                    >Beispiel: <cite>QR Code</cite></a
                >
            </li>
        </ul>
    </page-section>
</template>

<style lang="scss" scoped>
button {
    padding: #{get($sizes, "base") * 0.5};
    border: 0;

    background: none;
    cursor: pointer;

    font-weight: inherit;

    transition: background-color get($timings, "fast") linear;

    &:hover {
        background-color: palette("grey", "infra");
    }
}

cite {
    padding: 2px 4px;

    background-color: #fcedea;
    color: #c0341d;
    border-radius: 3px;

    font-family: get($fonts, "mono");
    font-size: 14px;
    font-style: normal;
    text-decoration: none;
    word-wrap: normal;
    white-space: pre-wrap;
}

a {
    display: inline-block;

    padding: #{get($sizes, "base") * 0.5};

    color: palette("text", "base");

    text-decoration: none;

    transition: background-color get($timings, "fast") linear;

    &:hover {
        background-color: palette("grey", "infra");
    }
}
</style>
